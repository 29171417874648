<template>
  <a-modal
      v-drag-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :width="800"
      @ok="handleOk"
      @cancel="visible = false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules" v-if="visible">
      <a-form-model-item
          label="Token类型"
          prop="type" required
          v-bind="formItemLayout"
      >
        <a-select
            class="input"
            v-model="form.type"
            placeholder="类型"
            allowClear
        >
          <a-select-option v-for="r in dict.cleanTokenType" :key="r.value.toString()">{{ r.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item label="备注" v-bind="formItemLayout">
        <a-input v-model="form.remark"/>
      </a-form-model-item>
      <a-form-model-item label="Token（每个一行）" v-bind="formItemLayout" prop="lines" required>
        <a-textarea v-model="form.lines" :rows="10"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {DICT} from "@/utils/dict.js";

export default {
  name: "importTokenModal",
  data() {
    return {
      dict: DICT,
      formItemLayout: {
        labelCol: {span: 6},
        wrapperCol: {span: 15},
      },
      title: "",
      visible: false,
      confirmLoading: false,
      form: {},
      rules: {
        lines: [
          {
            required: true,
            message: "请输入至少一个Token",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    showModal() {
      this.visible = true;
      this.title = "批量导入Token";
      this.form = {
        type: "1",
        remark: '',
        lines: ''
      };
    },
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.confirmLoading = true;
          this.$postJson("cleanSale/importToken", this.form).then((res) => {
            this.visible = false;
            this.confirmLoading = false;
            this.$message.success("导入成功");
            this.$emit("success");
          }).catch(() => {
            this.confirmLoading = false;
          });
        } else {
          this.confirmLoading = false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ant-input-number {
  width: 100% !important;
}
</style>
